*, *:before, *:after {
  box-sizing: border-box;
}

html {
  background-color: #262626;
}

body {
  max-width: 1680px;
  background-color: #FFFFFF;
  margin: 0 auto;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}
